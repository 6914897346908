export const navMainMenuItems = [
    {
        id: 1,
        title: "Home",
        path: "/",
        navBox: "nav-box-1",
        cName: "nav-item",
    },
    {
        id: 2,
        title: "Services",
        path: "/services",
        navBox: "nav-box-2",
        cName: "nav-item",
    },
    {
        id: 3,
        title: "About",
        path: "/about",
        navBox: "nav-box-3",
        cName: "nav-item",
    },
    {
        id: 4,
        title: "Mobile",
        path: "/",
        navBox: "nav-box-3",
        cName: "nav-item",
    },
  ];

  export const navServicesDropdownItems = [
    {
        id: 1,
        title: "Enterprise Architecture",
        path: "/enterprise-architecture",
        cName: "submenu-item",
    },
    {
        id: 2,
        title: "Software Lifecycle",
        path: "/software-lifecycle",
        cName: "submenu-item",
    },
    {
        id:3,
        title: "Information Technology",
        path: "/information-technology",
        cName: "submenu-item",
    },
  ];
  
  export const navAboutDropdownItems = [
    {
        id: 1,
        title: "Corporate",
        path: "/corporate",
        cName: "submenu-item",
    },
    {
        id: 2,
        title: "Privacy",
        path: "/privacy",
        cName: "submenu-item",
    },
    {
        id: 3,
        title: "Trademarks",
        path: "./trademarks",
        cName: "submenu-item",
    },
    {
        id: 4,
        title: "Partners",
        path: "/partners",
        cName: "submenu-item",
    },
  ];

  export const navMobileDropdownItems = [
    {
        id: 1,
        title: "Enterprise Architecture",
        path: "/enterprise-architecture",
        cName: "mobile-item",
    },
    {
        id: 2,
        title: "Software Lifecycle",
        path: "/software-lifecycle",
        cName: "mobile-item",
    },
    {
        id: 3,
        title: "Information Technology",
        path: "/information-technology",
        cName: "mobile-item",
    },
    {
        id: 4,
        title: "Corporate",
        path: "/corporate",
        cName: "mobile-item",
    },
    {
        id: 5,
        title: "Partners",
        path: "/partners",
        cName: "mobile-item",
    },
    {
        id: 6,
        title: "Privacy",
        path: "/privacy",
        cName: "mobile-item",
    },
    {
        id: 7,
        title: "Trademarks",
        path: "/trademarks",
        cName: "mobile-item",
    },
    {
        id: 8,
        title: "Employee Portal",
        path: "/employee-portal",
        cName: "mobile-item",
    },
  ];
