import React from 'react';
import { Link } from 'react-router-dom';
import '../../components/styles.css';

function Footer() {

    return (
        <div className='container-footer' >
            <div className='footer-box-1'>
                <span align="middle">
                    <p-sm>
                        Copyright &copy;1996-2023 ATHELON Corporation. All rights reserved. 
                        <Link to='/privacy' className='footerLinks'>See our policy</Link>.
                    </p-sm>
                </span>
            </div>
        </div>
    );

};

export default Footer;

/*

*/
