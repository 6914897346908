import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';

export default function Privacy() {
  return (

    <div className='container-content-about'
      style={{
        backgroundColor:'gray',
        color:'black',
        border:'40px',
          padding:'40px',
    }}
    >
      <div className='container-box-1' >
        <p-lg>Privacy Policy</p-lg>
      </div>

      <div className='container-box-4' align='center' style={{maxWidth:'400px', textAlign:'left'}}>
        <p><b><i>Session management</i></b></p><br />
        <p>This information is not used for personal identification: it simply allows us to 
              keep track of the pages that are of interest to users. Disabling cookies in your 
              browser will not affect how pages are viewed on this site.</p> <br />
        <p><b><i>Information we collect</i></b></p><br />
        <p>Athelon Corporation is the sole owner of the information collected on this Web site. 
            This information is not made publically available or shared with other companies or individuals.</p> <br />
      </div>

      <div className='content-box-3' align='center' style={{maxWidth:'400px', textAlign:'left'}}>
          <p></p>
      </div>

    </div>
  )
};

