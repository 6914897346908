import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';;

export default function Trademarks() {
  return (

  	<div className='container-content-about'
      style={{
        flexDirection:'row',
        flexWrap:'wrap',
        alignContent:'space-evenly',
        backgroundColor:'gray',
        color:'black',
        border:'10px',
        padding:'10px',
      }}
    >

      <div className='container-box-1'>
        <p-lg>Trademark and Legal Information</p-lg>
      </div>

      <div className='container-box-2' align='center' style={{maxWidth:'500px', textAlign:'left'}}>
        <p>Athelon is a registered trademark of the Athelon Corporation. 
        This trademark is protected under United States federal law. The corporation will 
        defend its trademark against illegal use or any attempts at dilution. 
        Athelon develops products for a large number of industries and reserves the 
        right to produce and sell software products for those industries using the registered Athelon mark.
        </p><br />

        <p>WinCOE and WinPACS are also Athelon trademarks and identify with their respective products.
        </p><br />

        <p>Athelon authorizes use of its trademarks when identifying with its products. 
        The company would appreciate you attributing ownership of Athelon trademarks to the 
        Athelon Corporation by using trademark symbols (™ or SM or ®).
        </p><br />

        <p>Other designated trademarks and brands used in illustrations on the Athelon Web site 
        are the property of their respective owners and are used to identify specific products. 
        These trademarks and brand names are used in strict accordance with the guidelines set 
        forth by their respective owners. 
        </p>
      </div>

      <div className='content-box-3' align='center' style={{maxWidth:'500px', textAlign:'left'}}>
        <p></p>
      </div>

    </div>
  )
};