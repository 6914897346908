import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';
import '../../components/pages/NavBar.css';
import { navMainMenuItems } from "./NavMenuItems";
import {ServiceDropdown, AboutDropdown, MobileDropdown} from "./Dropdown";


function NavBar() {
    
    const [serviceDropdown, setServiceDropdown] = useState(false);  
    const [aboutDropdown, setAboutDropdown] = useState(false); 
    const [mobileDropdown, setMobileDropdown] = useState(false); 
    const toggleMobileDropdown = () =>  setMobileDropdown(!mobileDropdown);

    return (
            <div className='container-nav'>
                <div className='nav-box-1'>
                    <Link to='/' className='nav-box-1' >
                        <span onMouseOver={() => setMobileDropdown(false)} >
                            ATHELON
                        </span>
                    </Link>
                </div>
                <div className='nav-box-2' >
                    <ul className='nav-items'>
                        {navMainMenuItems.map((item) => {
                            if (item.title === "Home") {
                                return (
                                    <li description="Home NoDropDown"
                                        key={item.id}
                                        className={item.cName}
                                        onMouseOver={() => setMobileDropdown(false)}
                                    >
                                        <Link className='nav-links' to='/' >
                                        <Icons.FaHome /> Home
                                        </Link>
                                    </li>
                                );
                            }
                            else if (item.title === "Services") {
                                return (
                                    <li description="Services Dropdown" 
                                        key={item.id}
                                        className={item.cName}
                                        onMouseEnter={() => setServiceDropdown(true)}
                                        onMouseLeave={() => setServiceDropdown(false)}
                                        onMouseOver={() => setMobileDropdown(false)}
                                    >
                                        <Link to={item.path} >
                                            Services
                                        </Link>
                                        {serviceDropdown && <ServiceDropdown />}
                                    </li>
                                );
                            }
                            else if (item.title === "About") {
                                return (
                                    <li description="About Dropdown"
                                        key={item.id}
                                        className={item.cName}
                                        onMouseEnter={() => setAboutDropdown(true)}
                                        onMouseLeave={() => setAboutDropdown(false)}
                                        onMouseOver={() => setMobileDropdown(false)}
                                    >
                                        <Link to={item.path} >
                                            About
                                        </Link>
                                        {aboutDropdown && <AboutDropdown />}
                                    </li>
                                );
                            }
                        
                        })}
                    </ul>
                </div>
                <div className='nav-box-3'>
                    <ul className='nav-menu' >
                        {navMainMenuItems.map((item) => {

                            if (item.title === "Mobile") {
                                return (
                                    <li description="Mobile FIXED Dropdown"
                                        key={item.id}
                                        className={item.cName}
                                        onClick={() => toggleMobileDropdown()}
                                    >
                                        <Link to={item.path} >
                                            <Icons.FaBars />
                                        </Link>
                                        {mobileDropdown && <MobileDropdown />}
                                    </li>
                                );
                            }

                        })}


                   </ul>
                </div>
            </div>
    );
};

export default NavBar;


