import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';

export default function InformationTechnology() {
    return (
      <div className='container-content-services'
        style={{
          backgroundColor:'gray',
          color:'black',
          border:'20px',
          padding:'20px',
		  	}}
		  >
        <div classNAme='content-box-1'  align='center'>
          <p-lg>Enterprise Architecture Design Services</p-lg>
        </div>
        <div className='content-box-2'  align='left'>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Access Control and Single Sign-On</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Identity Management and Governance</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Automated User Provisioning</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Service Request Management</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Role Based Authorization Design</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Corporate Directory Design (LDAP & RDB)</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Integration Advisor</p>
        </div>
        <div className='content-box-3' align='center' >
              <p></p>
        </div>
      </div>
    )
  };

/*

*/