import React from 'react';
import * as Icons from "react-icons/fa";

export default function Corporate() {
    return (
		<div className='container-content-about' 
			style={{
				backgroundColor:'gray',
				color:'black',
				border:'40px',
    			padding:'40px',
			}}
		>
			<div classNAme='content-box-1' align='center'>
				<p-vl>Corporate and Contact Information</p-vl>
			</div>
			<div className='content-box-4' align='center' style={{color:'black'}}>
				<table-contact align='left' >
					<tr>
						<td>Company Location:</td>
						<td>&nbsp;&nbsp;&nbsp;&nbsp;3600 Route 66, Neptune NJ 07753</td>
					</tr>
						<td>Direct all correspondence to: </td>
						<td>&nbsp;&nbsp;&nbsp;&nbsp;Athelon Corporation, 3600 Route 66, Suite 150, Neptune NJ 07753</td>
					<tr>
						<td>Phone:</td>
						<td>&nbsp;&nbsp;&nbsp;&nbsp;+1-732-681-0715</td>
					</tr>
					
					<tr>
						<td>Corporate Email:</td>
						<td>&nbsp;&nbsp;&nbsp;&nbsp;corporate@athelon.com</td>
					</tr>
					<tr>
						<td>Support Email:</td>
						<td>&nbsp;&nbsp;&nbsp;&nbsp;support@athelon.com</td>
					</tr>
				</table-contact>
			</div>
		</div>
    )
  };





