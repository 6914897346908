import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';

export default function SoftwareLifeCycle() {
    return (
      <div className='container-content-services'
        style={{
          backgroundColor:'gray',
          color:'black',
          border:'20px',
          padding:'20px',
		  	}}
		  >
        <div classNAme='content-box-1'  align='center'>
          <p-lg>Software Life Cycle Analysis and Design Services</p-lg>
        </div>
        <div className='content-box-2'  align='left'>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Feasibility Studies & Solution Analysis</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Product Analysis & Proof of Concept</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Requirement Specifications</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Architecture Specifications</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Design Specifications</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Test Specifications</p>
        </div>
        <div className='content-box-3' align='center' >
              <p></p>
        </div>
      </div>
    )
  };

  /*

  */