import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';

export default function EmployeePortal() {
  return ( 
	<div className='container-content-about'>
	<div className='content-box-2' align='center' >
			  <p></p>
		</div>
	<div className='container-box-1'>
	  <p-lg>&nbsp;&nbsp;The employee portal is currently off-line...</p-lg>
	</div>
	<div className='content-box-3' align='center' >
			  <p></p>
		</div>
	</div>
  )
};

