import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';

export default function About() {
    return (
      <div className='container-content-about'>
          <div className='content-box-1'>
              <h2>Contact and Corporate Information</h2>
          </div>
      </div>
    )
  };

