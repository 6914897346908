/*
  Version:        1.1.3
  Site:           Athelon Internet Site
  Website:        athelon.com
  Covers:         (.com, .net, .org)
  Framework:      React/Express
  Last updated:   2021-09-30
  Author:         Athelon Corporation
  email:          corporate@athelon.com
*/
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './components/styles.css';
import App from './App';

/*
  render initiates the "App" function (HTML code) and the first element in the DOM whose id="root"
*/
ReactDOM.render(
  <App />,
  document.getElementById('root')
);

