import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';

export default function Services() {
    return (
      <div className='container-content-services'>
          <div className='content-box-1'>
              <h2>Technology Services We Provide</h2>
          </div>
      </div>
    )
  };
