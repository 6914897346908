import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './components/styles.css';
import './App.css';

// main-emnus
import NavBar from './components/pages/NavBar';
import MainContent from './components/pages/MainContent';
import Services from './components/pages/Services';
import About from './components/pages/About';
import Footer from './components/pages/Footer';
// sub-menus
import SoftwareLifeCycle from './components/pages/SoftwareLifeCycle';
import InformationTechnology from './components/pages/InformationTechnology';
import EnterpriseArchitecture from './components/pages/EnterpriseArchitecture';
import Corporate from './components/pages/Corporate';
import Partners from './components/pages/Partners';
import Trademarks from './components/pages/Trademarks';
import Privacy from './components/pages/Privacy';
import EmployeePortal from './components/pages/EmployeePortal';


function App() {

  
  return (
      <Router>

        <div className='container-main'>
          <div className='main-box-1'>
            <NavBar />
          </div>
          <div className='main-box-2'>
            
            <Switch>
              <Route path='/' exact component={MainContent} />
              <Route path='/services' component={Services} />
              <Route path='/about' component={About} />
              <Route path='/software-lifecycle' component={SoftwareLifeCycle} />
              <Route path='/information-technology' component={InformationTechnology} />
              <Route path='/enterprise-architecture' component={EnterpriseArchitecture} />
              <Route path='/corporate' component={Corporate} />
              <Route path='/partners' component={Partners} />
              <Route path='/trademarks' component={Trademarks} />
              <Route path='/privacy' component={Privacy} />
              <Route path='/employee-portal' component={EmployeePortal} />
              <Route path='*' component={MainContent}/>
            </Switch>
            
          </div>
          <div className='main-box-3'>
            <Footer />
          </div>
        </div>

      </Router>
  );

}

export default App;
