import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';

export default function InformationTechnology() {
    return (
      <div className='container-content-services'
        style={{
          backgroundColor:'gray',
          color:'black',
          border:'20px',
          padding:'20px',
		  	}}
		  >
        <div classNAme='content-box-1'  align='center'>
          <p-lg>Information Technology Services</p-lg>
        </div>
        <div className='content-box-2'  align='left'>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Solution Design</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Solution Implementation</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Product Installation</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Development Management</p>
          <p><Icons.FaCaretRight color='red' />&nbsp;&nbsp;Technical Project Management</p>
        </div>
        <div className='content-box-3' align='center' >
              <p></p>
        </div>
      </div>
    )
  };

