import React, { useState } from "react";
import * as navItems from "./NavMenuItems";
import { Link } from "react-router-dom";
import "./Dropdown.css";


function ServiceDropdown() {
  const [serviceDropdown, setServiceDropdown] = useState(false);

  return (
    <div>
      <ul
        className={serviceDropdown ? "services-submenu clicked" : "services-submenu"}
        onClick={() => setServiceDropdown(!serviceDropdown)}
      >
        {navItems.navServicesDropdownItems.map((item) => {
          return (
            <li key={item.id}>
              <Link
                to={item.path}
                className={item.cName}
                onClick={() => setServiceDropdown(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function AboutDropdown() {
  const [aboutDropdown, setAboutDropdown] = useState(false);

  return (
    <div>
      <ul
        className={aboutDropdown ? "about-submenu clicked" : "about-submenu"}
        onClick={() => setAboutDropdown(!aboutDropdown)}
      >
        {navItems.navAboutDropdownItems.map((item) => {
          return (
            <li key={item.id}>
              <Link
                to={item.path}
                className={item.cName}
                onClick={() => setAboutDropdown(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function MobileDropdown() {
  const [mobileDropdown, setMobileDropdown] = useState(false);

  return (
    <div>
      <ul
        className={mobileDropdown ? "mobile-submenu clicked" : "mobile-submenu"}
        onClick={() => setMobileDropdown(!mobileDropdown)}
      >
        {navItems.navMobileDropdownItems.map((item) => {
          return (
            <li key={item.id}>
              <Link
                to={item.path}
                className={item.cName}
                onClick={() => setMobileDropdown(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}


export {ServiceDropdown, AboutDropdown, MobileDropdown};


/*


*/