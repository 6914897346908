import React from 'react';
import * as Icons from "react-icons/fa";
import '../../components/styles.css';

export default function MainContent() {
  return (
      <div className='container-content-home'>
        
          <div className='content-box-2' align='center' >
              <p></p>
          </div>
          <div className='content-box-1' align='center'>
            <h1>An Information Technology and <br/>
                Software Services Company</h1>
          </div>
          <div className='content-box-3' align='center'>
              <p-sm><i>In Business Since 1985</i></p-sm>
          </div>

      </div>
  )
};

